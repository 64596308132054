<template>
    <div class="audiolist">
        <!-- 下方的筛选项目连带搜索 -->
        <div class="search-box" v-if="list && listshow">
            <div class="search_inp">
                <el-input placeholder="请输入素材名称" prefix-icon="el-icon-search" size="small" v-model="name" clearable @input="inputBtn" @clear="reset" @keyup.enter.native="reset">
                </el-input>
            </div>
        </div>
        <!-- 列表标题 -->
        <div class="list-title" v-if="list && list.length">
            <div class="title">音乐</div>
            <div class="icon">
                <span v-if="!listshow" @click="listshowchange(true)">更多<i class="el-icon-arrow-right"></i></span>
                <i class="el-icon-arrow-left" v-else @click="listshowchange(false)"></i>
            </div>
        </div>
        <!-- 列表 -->
        <div class="contentbox" v-if="!listshow">
            <el-checkbox-group v-model="checkList">
                <div class="item openHover" v-for="(item, x) in list.slice(0, 3)" :key="x" @mouseenter="mouseenter(item, x)" @mouseleave="mouseleave(item, x)">
                    <div class="item-pic">
                        <div class="picbox">
                            <div class="img" @click="playBtn(item, x)">
                                <img class="img1" v-if="item.audioflag" :src="require('@/assets/images/workben/y_3.png')" alt="">
                                <template v-else>
                                    <img class="pause" v-if="item.audioStatus == 'pause'" :src="require('@/assets/images/workben/y_1.png')" alt="">
                                    <img class="play" v-else :src="require('@/assets/images/workben/y_2.png')" alt="">
                                    <audio :ref="'audioRef' + item.id" :src="item.url" hidden></audio>
                                </template>
                            </div>
                            <div class="info">
                                <div class="title">{{ item.name }}</div>
                                <div class="time">{{ item.exts.duration }}s</div>
                            </div>
                        </div>
                        <!-- 插入按钮 -->
                        <div class="insert">
                            <div class="button" @click="selectMaterials(item,1)">插入</div>
                        </div>
                        <!-- 应用到全局按钮 -->
                        <div class="insert_two">
                            <div class="button" @click="selectMaterials(item,2)">应用到全局</div>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>
        </div>
        <div class="contentbox scroll_bar" v-else infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
            <el-checkbox-group v-model="checkList">
                <div class="item openHover" v-for="(item, x) in list" :key="x" :class="{ 'selectHover': listshow }" @change="checkChange(item)" @mouseenter="mouseenter(item, x)" @mouseleave="mouseleave(item, x)">
                    <div class="item-pic">
                        <div class="picbox">
                            <div class="img" @click="playBtn(item, x)">
                                <img class="img1" v-if="item.audioflag" :src="require('@/assets/images/workben/y_3.png')" alt="">
                                <template v-else>
                                    <img class="pause" v-if="item.audioStatus == 'pause'" :src="require('@/assets/images/workben/y_1.png')" alt="">
                                    <img class="play" v-else :src="require('@/assets/images/workben/y_2.png')" alt="">
                                    <audio :ref="'audioRef' + item.id" :src="item.url" hidden></audio>
                                </template>
                            </div>
                            <div class="info">
                                <div class="title">{{ item.name }}</div>
                                <div class="time">{{ item.exts.duration }}s</div>
                            </div>
                        </div>
                        <!-- 插入按钮 -->
                        <div class="insert">
                            <div class="button" @click="selectMaterials(item,1)">插入</div>
                        </div>
                        <!-- 应用到全局按钮 -->
                        <div class="insert_two">
                            <div class="button" @click="selectMaterials(item,2)">应用到全局</div>
                        </div>
                        <!-- 左边的选中框 -->
                        <div class="selectCheck" v-if="item.checked || item.flag">
                            <el-checkbox :label="item.id"><br /></el-checkbox>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>
            <!-- 没有更多 -->
            <Loading :loading="loading" :list="list" :page="page" :totalPage="last_page">
            </Loading>
        </div>
    </div>
</template>

<script>
import {
    mapState
} from "vuex"
export default {
    components: {
        Loading: (resolve) => require(['@/components/loading/index'], resolve)
    },
    props: {
        listshow: {
            type: Boolean,
            default: false
        },
        // list: {
        //   type: Array,
        //   default: () => []
        // },
        showtype: {
            type: String,
            default: 'thumbnail'
        }
    },
    data() {
        return {
            checkList: [],
            name: '',
            isSelect: false, // 收否开启选择模式
            checkedAll: false, // 选择所有
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
        }
    },
    created() {
        this.load()

    },
    watch: {
        checkList(value) {
            this.$emit('upDataDelete', value)
        },
        list(value) {
            this.$emit('getList', value)
        }
    },
    computed: {
        ...mapState({
            currentTime: state => state.videoData.currentTime,//当前播放时间
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            duration: state => state.videoData.duration, //音频总时长
        }),
        searchForm() {
            return {
                page: this.page,
                limit: this.limit,
                type: 3,
                name: this.name
            }
        },
    },
    methods: {
        inputBtn() { },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getList()
        },
        // 重置
        reset() {
            this.list = []
            this.page = 1
            this.checkedAll = false
            this.deleteIds = []
            this.getList()
        },
        getList() {
            this.loading = true
            this.$workbenApi.self_material({
                ...this.searchForm
            }).then(res => {
                this.list = [...this.list, ...res?.result?.data] || []
                if (this.list.length) {
                    this.list.forEach(item => {
                        this.$set(item, 'audioflag', true)
                        this.$set(item, 'audioStatus', '')
                    })
                }
                this.loading = false
                this.last_page = res?.result?.last_page
                this.page = res?.result?.current_page ?? 1
            })
        },
        listshowchange(e) {
            this.$emit('listshowchange', e)
        },
        // 插入素材
        selectMaterials(info, type) {
            var that = this
            if (type == 1) {
                var arr = JSON.parse(JSON.stringify(that.sceneList))
                var idx = that.sceneIndex
                var scenes = arr[idx].scenes_conf || []
                // if (that.duration == 0) {
                //     return
                // }
                var str = {
                    id: new Date().getTime(),
                    type: 'audio',
                    name: info.name,
                    url: info.url,
                    duration: info.duration,
                    loop: true,
                    volume: 0.5,
                    background: false,
                    start: that.currentTime * 1000,
                }
                // 剩余时间
                var remainder = that.duration - that.currentTime
                // 如果剩余时间大于音频时长  直接取视频时长
                if (remainder >= info.duration) {
                    str.end = (that.currentTime + info.duration) * 1000
                } else {
                    // 如果小于  直接取总时长
                    str.end = that.duration * 1000
                }
                scenes.push(str)
                var params = {
                    type: 'conf',
                    info: scenes
                }
                // 修改数据
                that.$store.dispatch('videoData/modifyProduct', params)
                // 插入后选中
                that.$store.commit('videoData/SET_SHOW_ID', str.id)
            }
            // 全局
            if (type == 2) {
                let sounds = that.sceneList[that.sceneIndex].sounds
                let obj = {
                    name: info.name,
                    url: info.url,
                    volume: sounds ? sounds.volume : 0.5,
                    duration: info.duration,
                }
                that.$store.commit('videoData/SET_SOUNDS', obj)
            }
        },
        // 播放
        playBtn(item, index) {
            this.$set(item, 'audioflag', false)
            let recordAudio = this.$refs['audioRef' + item.id][0]
            if (recordAudio.paused) {
                recordAudio.play()
                this.$set(item, 'audioStatus', 'pause')
            } else {
                recordAudio.pause()
                this.$set(item, 'audioStatus', 'play')
            }
            // 其他音频全部停止
            this.list.forEach(ite => {
                if (ite.id != item.id) {
                    this.$set(ite, 'audioStatus', '')
                    this.$set(ite, 'audioflag', true)
                }
            })
            const audios = document.getElementsByTagName('audio');
            [].forEach.call(audios, function (i) {
                if (i !== recordAudio) {
                    i.pause();
                }
            })
        },
        // 鼠标进入
        mouseenter(item, index) {
            this.$set(item, 'audioflag', false)
            this.$set(item, 'flag', true)
        },
        // 鼠标离开
        mouseleave(item, index) {
            if (item.audioStatus != '') {
                this.$set(item, 'audioflag', false)
            } else {
                this.$set(item, 'audioflag', true)
            }
            this.$set(item, 'flag', false)
        },
        // 全选
        changeSelect(value) {
            this.checkList = value
            if (value.length) {
                this.list.forEach(item => {
                    this.$set(item, 'checked', true)
                })
            } else {
                this.list.forEach(item => {
                    this.$set(item, 'checked', false)
                })
            }
        },
        // 选择
        checkChange(item) {
            var flag = this.checkList.includes(item.id)
            if (flag) {
                this.$set(item, 'checked', true)
            } else {
                this.$set(item, 'checked', false)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../index.scss";

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 100px);
    // margin-right: -8px;
}
</style>